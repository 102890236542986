import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getProducts } from '../methods'
import { setCatalogProducts } from './../actions'

import ProductCard from './ProductCard'

import { Ring } from '@uiball/loaders'

import Pagination from './Pagination'
import CarouselProductsHome from './CarouselProductsHome'

export const CatalogProducts = ({productNameOk}) => {

  const productName = localStorage.getItem('productName') || ''
  const dispatch = useDispatch()
  const catalogProducts = useSelector((state) => state.catalogProducts)
  let configData = useSelector((state) => state.configData)
  let dataStore = useSelector((state) => state.dataStore)

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState()

  useEffect(() => {
    ;(async () => {
      if (configData) {
        let data = await getProducts(page, 10, productName)
        dispatch(setCatalogProducts(data))
        setLastPage(lastPage)
      }
    })()
    return () => {
      dispatch(setCatalogProducts([]))
    }
  }, [page, productName, configData])

  const containerRef = useRef()

  const scrollToTop = () => {
    if (containerRef.current) {
      const addScrollResponsive = window.innerWidth > 768 ? 150 : 180
      window.scrollTo({
        top: containerRef.current.offsetTop - addScrollResponsive,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    scrollToTop()
  }, [page])

  return (
    <>
      <div className="flex justify-end w-max my-[25px] gap-2 bg-black py-2 px-8 pl-20 rounded-r-[15px] text-white text-[16px]">
        <h2 className="text-blue" ref={containerRef}>
          PRODUCTOS
        </h2>
      </div>
      {dataStore?.default_productohome === '1' && productNameOk === undefined ? (
        <CarouselProductsHome mostrarTitulo={false}/> 
      ) : null}
      <div className="sm:w-[80vw] mx-auto flex flex-col flex-wrap">
        <div>
          {catalogProducts.data?.length > 0 ? (
            <>
              <div className="flex sm:gap-[10px] lg:gap-[10px] flex-wrap justify-start">
                {catalogProducts.data?.map((p) => (
                  <ProductCard key={p.id} product={p} />
                ))}
              </div>
              <Pagination
                actualPage={page}
                setPage={setPage}
                lastPage={catalogProducts.lastPage}
                productName={productName}
              />
            </>
          ) : (
            <div className="h-[60vh] flex justify-center items-center w-full">
              <Ring
                className="text-black"
                size={40}
                lineWeight={5}
                speed={2}
                color="black"
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
