import { useNavigate, useParams } from 'react-router-dom'

import { CaretRight, CaretLeft } from '@phosphor-icons/react'

export default function Pagination({
  actualPage,
  setPage,
  lastPage,
  productName,
  url = '',
}) {
  const params = useParams()
  const actualPageUrl = params.actualPage
  let navigate = useNavigate()
  function handlePreviousPage() {
    if (+actualPage > 1) {
      setPage(+actualPage - 1)
      if (url !== '' && productName !== undefined) {
        navigate(`/${url}/${productName}/${+actualPage - 1}`)
      } else {
        if (url !== '' && productName === '' || productName === undefined) {
          navigate(`/${url}/${+actualPage - 1}`)
        } else {
          if(productName !== '' && productName !== undefined){
            navigate(`/${productName}/${+actualPage - 1}`)
          }else{
            navigate(`/${+actualPage - 1}`)
          }
        }
      }
    }
  }
  function handleNextPage() {
    if (+actualPage < lastPage) {
      setPage(+actualPage + 1)
      if (url !== '' && productName !== undefined) {
        navigate(`/${url}/${productName}/${+actualPage + 1}`)
      } else {
        if (url !== '' && productName === '' || productName === undefined) {
          navigate(`/${url}/${+actualPage + 1}`)
        } else {
          if(productName !== '' && productName !== undefined){
            navigate(`/${productName}/${+actualPage + 1}`)
          }else{
            navigate(`/${+actualPage + 1}`)
          }
        }
      }
    }
  }
  return (
    <div className="w-max mx-auto bg-black text-blue flex justify-center items-center my-4 sm:my-8 py-2 border-1 border-black rounded-[5px]">
      <CaretLeft
        className="px-2 w-[50px] text-blue duration-200 hover:text-white hover:cursor-pointer"
        onClick={() => handlePreviousPage()}
        size={22}
      />
      <select
        className="mx-2 text-center bg-black text-xs hover:text-white hover:cursor-pointer duration-200"
        value={actualPageUrl ? actualPageUrl : actualPage}
        onChange={(e) => {
          setPage(+e.target.value)
          if (url !== '' && productName !== undefined) {
            navigate(`/${url}/${productName}/${e.target.value}`)
          } else {
            if (url !== '' && productName === undefined) {
              navigate(`/${url}/${e.target.value}`)
            } else {
              if(productName !== '' && productName !== undefined){
                navigate(`/${productName}/${e.target.value}`)
              }else{
                navigate(`/${e.target.value}`)
              }
            }
          }
        }}
      >
        {[...Array(lastPage)]?.map((_, index) => (
          <option key={index + 1} value={index + 1}>
            PÁGINA {index + 1}
          </option>
        ))}
      </select>
      <CaretRight
        className="px-2 w-[50px] text-blue duration-200 hover:text-white hover:cursor-pointer"
        onClick={() => handleNextPage()}
        size={22}
      />
    </div>
  )
}
