import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function DisplayInfoEcommerce({ text }) {
  if (!text) return;
  return (
    <div className="w-[80vw] mx-auto bg-black text-blue my-2 rounded-[5px] py-2 px-8 text-sm text-center border-1 border-blue">
      {text.toUpperCase()}
    </div>
  );
}

export default function InfoEcommerce() {
  let dataStore = useSelector((state) => state.dataStore);

  const [infoKeys, setInfoKeys] = useState([]);

  function getArrayInfoKeys(object) {
    const keys = [];
    const regex = /^ecommerce_info\d*$/;
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        if (regex.test(key)) {
          keys.push(key);
        }
      }
    }
    return keys;
  }

  useEffect(() => {
    setInfoKeys(getArrayInfoKeys(dataStore));
  }, []);

  return (
    <>
      {infoKeys?.map((key, i) => {
        return (
          +dataStore[key] === 1 && (
            <DisplayInfoEcommerce
              key={i}
              text={
                i === 0
                  ? dataStore["ecommerce_informacion"]
                  : dataStore["ecommerce_informacion" + (i + 1)]
              }
            />
          )
        );
      })}
    </>
  );
}
