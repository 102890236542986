import { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { useNavigate, Link } from 'react-router-dom'

import { getOrders } from './../methods/index'
import { setOrders as setGlobalOrders } from './../actions/index'

import { Ring } from '@uiball/loaders'

import { UseScrollToTop } from './../utilities/UseScrollToTop'

import { ModalMaintenance } from './ModalMaintenance'

export default function MyOrders() {

  let dataStore = useSelector((state) => state.dataStore)
  let enMantenimiento = dataStore?.default_mantenimiento  

  let navigate = useNavigate()

  let dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  const [numOrders, setNumOrders] = useState(0)
  const [orders, setOrders] = useState('')

  useEffect(() => {
    ;(async () => {
      const orders = await getOrders(user.id)
      setOrders(orders)
      dispatch(setGlobalOrders(orders))
      setNumOrders(orders.length)
    })()
  }, [])

  return (
    <div className="w-[80vw] mx-auto">
      <ModalMaintenance
        show={enMantenimiento}
      />
      <UseScrollToTop />
      <p className="text-[13px] flex items-center gap-2 my-12">
        MIS PEDIDOS /{' '}
        <span className="font-bold text-[16px] text-white bg-black p-2 rounded-full w-[30px] h-[30px] flex justify-center items-center">
          {numOrders}
        </span>{' '}
        <span className="font-bold text-[16px]"> ÓRDENES</span>
      </p>
      <h2 className="text-[22px] border-b-1 border-black pb-2">Mis pedidos</h2>
      {orders.length > 0 ? (
        <div className="flex flex-wrap gap-4 w-full text-center rounded-[5px] mt-12 mb-28">
          {orders.map((order) => {
            return (
              <Link
                className="flex flex-col w-[140px] overflow-hidden sm:w-[200px] border-1 border-gray-01 rounded-t-[10px] rounded-b-[5px] hover:border-black group duration-200"
                to={`/mis-pedidos/${order.facturaid}`}
                key={order.facturaid}
              >
                <p className="pl-2 text-left italic text-white p-1 rounded-t-[5px] text-xs bg-black group-hover:text-blue">
                  #{order.pedidoplacaid}
                </p>
                <div className="flex flex-col text-center text-sm">
                  <p className="p-2 border-b-1 border-gray-01 mx-2">
                    <span className="font-bold">Usuario:</span>{' '}
                    {order.nombrecliente.length > 20
                      ? order.nombrecliente.slice(0, 20) + '...'
                      : order.nombrecliente}
                  </p>
                  <p className="p-2 border-b-1 border-gray-01 mx-2">
                    <span className="font-bold">Tienda:</span>{' '}
                    {order.nombretienda}
                  </p>
                  <p className="p-2 border-b-1 border-gray-01 mx-2">
                    <span className="font-bold">Fecha:</span>{' '}
                    {order.fechafactura}
                  </p>
                  <p className="p-2 mx-2">
                    <span className="font-bold">Total:</span> ${order.total}
                  </p>
                </div>
              </Link>
            )
          })}
        </div>
      ) : typeof orders === 'string' ? (
        <div className="h-[45vh] flex justify-center items-center">
          <Ring
            className="text-black"
            size={40}
            lineWeight={5}
            speed={2}
            color="black"
          />
        </div>
      ) : (
        <div className="h-[45vh] flex justify-center items-center">
          <p>No tiene ninguna orden realizada</p>
        </div>
      )}
    </div>
  )
}
