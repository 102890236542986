import { Link } from 'react-router-dom'

import { ModalMaintenance } from './ModalMaintenance'
import { useSelector } from 'react-redux'

export default function StatePurchase() {

  let dataStore = useSelector((state) => state.dataStore)
  let enMantenimiento = dataStore?.default_mantenimiento  

  return (
    <div className="w-[80vw] mx-auto mt-32 mb-32 text-center">
      <ModalMaintenance
        show={enMantenimiento}
      />
      <h2 className="w-max mx-auto pb-2 my-4 text-xl border-b-1 border-gray-01">
        Gracias por comprar con nosotros
      </h2>
      <p className="my-8">SU PEDIDO HA SIDO REALIZADO CON ÉXITO</p>
      <Link
        className="flex text-sm w-max my-4 mx-auto bg-black text-blue px-4 py-2 rounded-[5px] hover:text-white duration-200"
        to="/mis-pedidos"
      >
        VER MIS PEDIDOS
      </Link>
    </div>
  )
}
