import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import { useNavigate, useParams } from 'react-router-dom';

import { cancelarCompra, getCiudadByid, getDatosPedido, getMensajes, getOrderDetail, getProvinciaById, getPuntoDeRetiroById, sendMessage } from './../methods/index'

import { Ring } from '@uiball/loaders'

import { User, Truck, HandCoins } from "@phosphor-icons/react";

import mercadopago from './iconomercadopago.png'
import { toast } from 'sonner';

import { ModalMaintenance } from './ModalMaintenance'
import { ModalMessage } from './ModalMessage';

export default function MyOrders() {

    let dataStore = useSelector((state) => state.dataStore)
    let enMantenimiento = dataStore?.default_mantenimiento  

    const { orderId } = useParams();
    let navigate = useNavigate()

    const user = useSelector(state => state.user)

    const [order, setOrder] = useState('')
    const [urlEtiqueta, setUrlEtiqueta] = useState('')
    const [datosEnvio, setDatosEnvio] = useState('')
    const [detalle, setDetalle] = useState('')
    const [envio, setEnvio] = useState('')
    const [punto, setPunto] = useState('')
    const [provincia, setProvincia] = useState('')
    const [ciudad, setCiudad] = useState('')
    const [messages, setMessages] = useState([])

    const [show, setShow] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = (row) => {
      setShow(true)
    }

    const [input, setInput] = useState({
        titulo: '',
        mensaje: '',
        usuariodestinoid: '',
        pedidoid: ''
    })

    const handleChange = (e) => {
        e.preventDefault()
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    async function handleSendMessage(input) {
        let response = await sendMessage(input)
        setInput({
            titulo: '',
            mensaje: '',
            usuariodestinoid: '',
            pedidoid: ''
        })
        const messagesList = await getMensajes(
            order.cabecera.pedidoplacaid,
        )
        setMessages(messagesList)
    }

    async function cancelar(pedido){
        let data = await cancelarCompra(pedido)
        toast(
            <div className="flex flex-col gap-2 text-[16px]">
                <p>{data.mensaje}</p>
            </div>
        )
        const orderExtraDetail = await getOrderDetail(orderId, user.id)
        setOrder(orderExtraDetail)
    }


    useEffect(() => {
        (async () => {
            const orderExtraDetail = await getOrderDetail(orderId, user.id)
            const messagesList = await getMensajes(
                orderExtraDetail.cabecera.pedidoplacaid,
                orderExtraDetail.cabecera.clienteid,
                user.id
            )
            setMessages(messagesList)
            setOrder(orderExtraDetail)
            let envio = Object.values(orderExtraDetail.detalle)
            let t_total = envio.pop()
            let t_iva = envio.pop()
            let t_precio = envio.pop()
            setDetalle(envio)
            if(orderExtraDetail.cabecera.tipo_envio === "Pickit"){
                setEnvio(envio.reverse().pop())
                const datosAux = await getDatosPedido(orderExtraDetail.cabecera.clientepickitid, user.id)
                setUrlEtiqueta(datosAux.envios_pickit[orderExtraDetail.cabecera.clientepickitid].url_tracking)
                setDatosEnvio(datosAux.envios_pickit[orderExtraDetail.cabecera.clientepickitid])
                if(datosAux.envios_pickit[orderExtraDetail.cabecera.clientepickitid].origen_envio === "1"){
                    const puntoAux = await getPuntoDeRetiroById(datosAux.envios_pickit[orderExtraDetail.cabecera.clientepickitid].punto_envio)
                    setPunto(puntoAux)
                }else{
                    const prov = await getProvinciaById('40',datosAux.envios_pickit[orderExtraDetail.cabecera.clientepickitid].provinciaid)
                    setProvincia(prov)
                    const ciu = await getCiudadByid('40', 
                        datosAux.envios_pickit[orderExtraDetail.cabecera.clientepickitid].provinciaid, 
                        datosAux.envios_pickit[orderExtraDetail.cabecera.clientepickitid].localidadid
                    )
                    setCiudad(ciu)
                }
            }
        })()
    }, [])

    return(
        <div className='w-[80vw] mx-auto'>
            <ModalMaintenance
                show={enMantenimiento}
            />
            <ModalMessage
                show={show}
                handleClose={handleClose}
                messages={messages}
                pedidoid={order && order.cabecera.pedidoplacaid}
                clienteid={user.id}
                input={input}
                setInput={setInput}
                sendMessage={handleSendMessage}
                handleChange={handleChange}
            />
            <p className='text-[13px] mt-12 flex items-center gap-2 my-12'>MIS PEDIDOS /<span className='font-bold text-[16px]'> DETALLE</span></p>
            <h2 className='text-[22px] border-b-1 border-black pb-2'>Detalle del pedido</h2>
            {order ? 
                <div className='mt-12 mb-4'>
                    <div className='flex gap-4 items-center'>
                        <h3 className='w-max text-xl font-bold text-blue bg-black p-2'>#{order.cabecera.pedidoplacaid}</h3>
                        <p className={`italic text-white p-2 text-xs rounded-[5px] font-bold ${order.cabecera.descripcionestado === 'Pendiente' ? 'bg-warning' : order.cabecera.descripcionestado === 'Cancelado' ? 'bg-error' : 'bg-success'}`}>{order.cabecera.descripcionestado}</p>
                        <button 
                        disabled={order.cabecera.descripcionestado === 'Cancelado' ? true : false}
                        className={`p-2 text-sm text-white hover:text-error hover:bg-white duration-200 ${order.cabecera.descripcionestado === 'Cancelado' ? 'bg-white text-error' : 'bg-error'}`}
                        onClick={()=>cancelar(order.cabecera.pedidoplacaid)}
                        >Cancelar compra</button>
                        <button
                            onClick={()=>handleShow()}
                            className='text-sm bg-blue hover:bg-black hover:text-blue p-2 text-white '
                        >
                            Mensajes
                        </button>
                    </div>
                    <p className='text-xs italic mt-8 mb-2 text-black'>{order.detalle[Object.keys(order.detalle)[0]].fechapedido}</p>
                    <div className='flex flex-wrap gap-2 flex-col sm:flex-row'>
                        <div className='w-full flex-grow sm:w-1/4 flex flex-col mb-8 gap-2 bg-gray-01 p-4 text-sm'>
                            <div className='flex justify-between items-center text-[16px] border-b-1 border-b-black mb-2'>
                                <h4 className='font-bold'>CLIENTE</h4>
                                <User className='bg-black text-gray-01 p-1 rounded-t-[10px]' size={35} />
                            </div>
                            <p><span className='font-bold'>NOMBRE:</span> {order.cabecera.nombre}</p>
                            <p><span className='font-bold'>EMAIL:</span> {order.cabecera.email}</p>
                            <p><span className='font-bold'>TELÉFONO:</span> {order.cabecera.telefono}</p>
                        </div>
                        <div className='w-full flex-grow sm:w-1/4 flex flex-col mb-8 gap-2 bg-gray-01 p-4 text-sm'>
                            <div className='flex justify-between items-center text-[16px] border-b-1 border-b-black mb-2'>
                                    <h4 className='font-bold'>INFORMACIÓN DEL PAGO</h4>
                                    <HandCoins className='bg-black text-gray-01 p-1 rounded-t-[10px]' size={35} />
                            </div>
                            <p style={{display:"flex", flexDirection:"row"}}><span className='font-bold'>TIPO DE PAGO:</span>&nbsp;{order.cabecera.descripcionpago} {order.cabecera.descripcionpago === "Mercado Pago" ? <img style={{width:20}} src={mercadopago}/> : null}</p>
                            <p><span className='font-bold'>TOTAL A PAGAR:</span>&nbsp;${(order.cabecera.monto).toLocaleString('es-ES', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                            <p><span className='font-bold'>TOTAL PAGADO:</span>&nbsp;${(order.pagos.totalpagado).toLocaleString('es-ES', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                            <p><span className='font-bold'>ESTADO:</span>&nbsp;{Object.values(order.pagos)[0].status}</p>
                        </div>
                        {
                            order.cabecera.tipo_envio === 'Pickit' && datosEnvio.origen_envio === "1" ?
                            <div className='w-full flex-grow sm:w-1/4 flex flex-col mb-8 gap-2 bg-gray-01 p-4 text-sm'>
                                <div className='flex justify-between items-center text-[16px] border-b-1 border-b-black mb-2'>
                                    <h4 className='font-bold'>INFORMACIÓN DEL ENVÍO</h4>
                                    <Truck className='bg-black text-gray-01 p-1 rounded-t-[10px]' size={35} />
                                </div>
                                <p><span className='font-bold'>MÉTODO DE ENVÍO:</span>&nbsp;{order.cabecera.tipo_envio}</p>
                                <p><span className='font-bold'>NOMBRE PUNTO:</span>&nbsp;{punto.name}</p>
                                <p><span className='font-bold'>DIRECCIÓN:</span>&nbsp;{punto.address}</p>
                                <a href={urlEtiqueta} target='_blank'>
                                    <button 
                                    className='bg-black p-2 text-sm text-white border-1 border-black hover:text-black hover:bg-white duration-200'
                                    >SEGUIMIENTO ENV&Iacute;O</button>
                                </a>
                            </div> : 
                            order.cabecera.tipo_envio === 'Pickit' && datosEnvio.origen_envio === "2" ?
                            <div className='w-full flex-grow sm:w-1/4 flex flex-col mb-8 gap-2 bg-gray-01 p-4 text-sm'>
                                <div className='flex justify-between items-center text-[16px] border-b-1 border-b-black mb-2'>
                                    <h4 className='font-bold'>INFORMACIÓN DEL ENVÍO</h4>
                                    <Truck className='bg-black text-gray-01 p-1 rounded-t-[10px]' size={35} />
                                </div>
                                <p><span className='font-bold'>MÉTODO DE ENVÍO:</span>&nbsp;{order.cabecera.tipo_envio}</p>
                                <p><span className='font-bold'>DIRECCIÓN:</span>&nbsp;{datosEnvio.calle} {datosEnvio.altura}, {ciudad}, {provincia}</p>
                                <p><span className='font-bold'>C&Oacute;DIGO POSTAL:</span>&nbsp;{datosEnvio.codigopostal}</p>
                                <a href={urlEtiqueta} target='_blank'>
                                        <button 
                                        className='bg-black p-2 text-sm text-white border-1 border-black hover:text-black hover:bg-white duration-200'
                                        >SEGUIMIENTO ENV&Iacute;O</button>
                                    </a>
                            </div> : null
                        }
                        {
                            order.cabecera.tipo_envio === "Retiro por Sucursal" ?
                            <div className='w-full flex-grow sm:w-1/4 flex flex-col mb-8 gap-2 bg-gray-01 p-4 text-sm'>
                                <div className='flex justify-between items-center text-[16px] border-b-1 border-b-black mb-2'>
                                    <h4 className='font-bold'>INFORMACIÓN DEL ENVÍO</h4>
                                    <Truck className='bg-black text-gray-01 p-1 rounded-t-[10px]' size={35} />
                                </div>
                                <p><span className='font-bold'>MÉTODO DE ENVÍO:</span>&nbsp;{order.cabecera.tipo_envio}</p>
                            </div> : order.cabecera.tipo_envio === "Envio a Domicilio" ?
                            <div className='w-full flex-grow sm:w-1/4 flex flex-col mb-8 gap-2 bg-gray-01 p-4 text-sm'>
                                <div className='flex justify-between items-center text-[16px] border-b-1 border-b-black mb-2'>
                                    <h4 className='font-bold'>INFORMACIÓN DEL ENVÍO</h4>
                                    <Truck className='bg-black text-gray-01 p-1 rounded-t-[10px]' size={35} />
                                </div>
                                <p><span className='font-bold'>MÉTODO DE ENVÍO:</span>&nbsp;{order.cabecera.tipo_envio}</p>
                                <p><span className='font-bold'>DIRECCIÓN:</span>&nbsp;{order.cabecera.direccion} {order.cabecera.altura}</p>
                            </div> : null
                        }
                    </div>
                    <div className='flex flex-col gap-2 overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead>
                                <tr className='text-sm text-blue bg-black rounded-t-[5px] flex text-md'>
                                    <th className='p-2 w-[200px] sm:w-[300px]'>Producto</th>
                                    <th className='p-2 w-[100px] sm:w-[200px]'>Cantidad</th>
                                    <th className='p-2 w-[200px] sm:w-[300px]'>Código</th>
                                    <th className='p-2 w-[100px] sm:w-[200px]'>Descuento</th>
                                    <th className='p-2 w-[100px] sm:w-[200px]'>Precio</th>
                                </tr>
                            </thead>
                            <tbody className='text-xs sm:text-sm'>
                                {detalle.map((productDetail, i) => {
                                    return(
                                        <tr className={`py-4 flex border-1 border-gray-01 items-center text-sm ${(i % 2 === 0) && 'bg-gray-01'}`}>
                                            <td className='w-[200px] sm:w-[300px] px-2'>{productDetail.nombreproducto}</td>
                                            <td className='w-[100px] sm:w-[200px]'>{productDetail.cantidad}</td>
                                            <td className='w-[200px] sm:w-[300px]'>{productDetail.codigoarticulo}</td>
                                            <td className='w-[100px] sm:w-[200px]'>{productDetail.descuentos}</td>
                                            <td className='w-[100px] sm:w-[200px]'>${productDetail.precioventa}</td>
                                        </tr>
                                    )} 
                                )}
                                {                                
                                    order.cabecera.tipo_envio === "Pickit" &&
                                    <tr className={`py-4 flex border-1 border-gray-01 items-center text-sm ${(detalle.length % 2 === 0) && 'bg-gray-01'}` }>
                                    <td className='w-[200px] sm:w-[300px] px-2'>Env&iacute;o</td>
                                    <td className='w-[100px] sm:w-[200px]'></td>
                                    <td className='w-[200px] sm:w-[300px]'></td>
                                    <td className='w-[100px] sm:w-[200px] '></td>
                                    <td className='w-[100px] sm:w-[200px] '>${envio.totalventa.toFixed(2)}</td>
                                </tr>
                                }
                            </tbody>
                        </table>
                        {/* <div className='relative w-1/4 flex flex-col mb-8 gap-2 bg-gray-01 px-8 py-4 text-sm'>
                            <Coins className='absolute right-2 bg-black text-gray-01 p-1 rounded-[10px]' size={32} />
                            <p>Precio: ${(order.detalle.t_precio).toLocaleString('es-ES', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                            <p>Descuento: ${(order.detalle[Object.keys(order.detalle)[0]].descuentos).toLocaleString('es-ES', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                            <p>IVA: ${(order.detalle.t_iva).toLocaleString('es-ES', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                            <p>Total: ${(order.detalle.t_total).toLocaleString('es-ES', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                        </div> */}
                    </div>
                </div>
            :
                <div className="h-[45vh] flex justify-center items-center">
                    {/* <p>No existe ningún detalle con ese número de órden</p> */}
                    <Ring className='text-black' size={40} lineWeight={5} speed={2} color="black" />
                </div>
            }
            <button className='bg-black p-2 text-sm text-white mb-12 border-1 border-black hover:text-black hover:bg-white duration-200' onClick={() => navigate('/mis-pedidos')}>Volver</button>
        </div>
    )
}