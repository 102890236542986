import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import SearchBar from './SearchBar'
import SubNavBar from './SubNavBar'

import { List, ShoppingBag, UserCircle, WhatsappLogo } from '@phosphor-icons/react'
import { ProfileMenu } from './ProfileMenu'

import { Toaster, toast } from 'sonner'

import { setCatalogProducts } from './../actions/index.js'

import { urlCore } from '../config'

import useComponentVisible from '../utilities/UseComponentVisible.jsx'
import { useEffect, useState } from 'react'
import { getProducts, getTotalProducts } from '../methods/index.js'
import logo_default from '../media/logo_develshops.png'
import { Help } from './Help.jsx'

import { logOut, setClientSession } from './../actions/index.js'
import { useNavigate } from 'react-router-dom'
import {
  logOut as logOutMethod,
  obtenterClientSession,
} from './../methods/index.js'

export default function NavBar() {
  const dispatch = useDispatch()

  let clientSession = useSelector((state) => state.clientSession)
  let user = useSelector((state) => state.user)
  let totalProducts = useSelector((state) => state.totalProducts)
  let dataStore = useSelector((state) => state.dataStore)
  let configData = useSelector((state) => state.configData)

  let colores = JSON.parse(localStorage.getItem('dataStore')) || ''

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState()  

  const [open, setOpen] = useState(false)

  const navigate = useNavigate()

  function handleMyOrders() {
    setIsComponentVisible(false)
    navigate('./mis-pedidos')
  }

  function handleEditProfile() {
    setIsComponentVisible(false)
    navigate('./editar-perfil')
  }

  async function handleLogOut() {
    const isLogOut = await logOutMethod(user.id)
    if (isLogOut) {
      dispatch(setClientSession(await obtenterClientSession()))
      dispatch(logOut())
      setIsComponentVisible(false)
      toast(
        <div className="flex flex-col gap-2 text-[13px]">
          <p>Te has deslogueado exitosamente</p>
        </div>
      )
      navigate('/')
    }
  }

  async function resetProducts(){
    localStorage.setItem('productName','')
    let data = await getProducts(page, 10, '')
    dispatch(setCatalogProducts(data))
    setLastPage(lastPage)
  }

  useEffect(() =>{
    ;(async () => {
      let clientId = user ? user.id : clientSession
      await getTotalProducts(clientId)
    })()
  },[])

  return (
    <div className="flex flex-col text-[13px] sticky top-0 z-20 w-full flex-start">
      <Toaster />
      <div style={{
      backgroundColor: colores.ecommerce_colorPrimario
      }}>
        <div className="w-full sm:w-[80vw] mx-auto p-2">
          <h1 className="hidden">Develone</h1>
          <div
            ref={ref}
            className="flex items-center justify-between flex-wrap"
          >
            <Link className="sm:hidden relative group text-white" to="/carrito">
              <ShoppingBag className="group-hover:text-blue" size={36} />
              {/* NUMBER OF PRODUCTS ADDED TO THE CART */}
              <div className="flex justify-center items-center absolute w-[26px] h-[26px] bottom-[-10px] right-[-10px] border-2 bg-black border-white rounded-full group-hover:text-black group-hover:bg-blue group-hover:border-black">
                <span className="text-[10px] group-hover:font-bold">
                  {totalProducts}
                </span>
              </div>
            </Link>
            <Link to="/" onClick={resetProducts}>
            {
              dataStore?.imagenlogo ?
              <img
                onClick={resetProducts}
                className="w-[60px] object-cover"
                src={`${urlCore}/archivos/logos/cart/${configData?.tiendaid}/${dataStore?.imagenlogo}`}
                alt="Logo Develone"
              /> :
              <img
                onClick={resetProducts}
                className="w-[60px] object-cover"
                src={logo_default}
                alt="Logo Develone"
              /> 
            }
            </Link>
            <div className="hidden sm:flex">
              <SearchBar />
            </div>
          
          <div className="hidden sm:flex items-center text-white gap-2">
              <div className='relative'> 
                {/* AYUDA */}
                <Help dataStore={dataStore}/>
              </div>
              {user ? (
                <ProfileMenu user={user} />
              ) : (
              <div className='relative'> 
                <div 
                  className='font-bold flex flex-col justify-center items-center py-1 px-2 rounded-[5px] hover:cursor-pointer hover:text-blue duration-200 min-w-[110px]'
                  onClick={() => setIsComponentVisible(!isComponentVisible)}>
                  <UserCircle size={36} />
                  Mi cuenta
                </div>
                {/* MENU PROFILE */}
                {isComponentVisible && (
                  <div className="flex flex-col items-center gap-2 text-white z-10 absolute top-[70px] bg-white p-2 rounded shadow-xl border-1 border-gray-01 w-[100%] min-w-[120px]">
                    <Link className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200" to="iniciarsesion">
                    <button
                      className="border-1 border-black bg-black px-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
                      onClick={() => setIsComponentVisible(!isComponentVisible)}
                    >
                      Ingresar
                    </button>
                    </Link>
                    <Link className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200" to="registrarse">
                    <button
                      className="border-1 border-black bg-black px-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
                      onClick={() => setIsComponentVisible(!isComponentVisible)}
                    >
                      Registrarse
                    </button>
                    </Link>
                  </div>
                )}
              </div>
              )}
              <Link className="relative group" to="/carrito">
                <ShoppingBag className="group-hover:text-blue" size={36} />
                
                <div className="flex justify-center items-center absolute w-[26px] h-[26px] bottom-[-10px] right-[-10px] border-2 bg-black border-white rounded-full group-hover:text-black group-hover:bg-blue group-hover:border-black">
                  <span className="text-[10px] group-hover:font-bold">
                    {totalProducts}
                  </span>
                </div>
              </Link>
            </div>
            <List
              className="text-white sm:hidden mr-2 hover:cursor-pointer"
              size={32}
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            />
            <div className="flex sm:hidden w-[100%]">
              <SearchBar />
            </div>
            {isComponentVisible && (
              <div className="md:hidden lg:hidden flex flex-col items-center gap-2 text-white z-10 absolute top-[70px] bg-white p-2 right-0 rounded shadow-xl border-1 border-gray-01 w-[100%]">
                {
                  user ?
                  <button
                    className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
                    onClick={() => handleEditProfile()}
                  >
                    Editar perfil
                  </button>: 
                  <Link className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200" to="iniciarsesion">
                    <button
                      className="border-1 border-black bg-black text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
                      onClick={() => setIsComponentVisible(!isComponentVisible)}
                    >
                      Ingresar
                    </button>
                  </Link> 
                  
                }
                {
                  user ?
                  <button
                    className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
                    onClick={() => handleMyOrders()}
                  >
                    Mis pedidos
                  </button>: 
                  <Link className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200" to="registrarse">
                    <button
                      className="border-1 border-black bg-black text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
                      onClick={() => setIsComponentVisible(!isComponentVisible)}
                    >
                      Registrarse
                    </button>
                  </Link>
                }
                <button
                  className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
                >
                <Link
                  className='flex items-center justify-center'
                  to={dataStore?.url_whatsapp_api}
                  target="_blank"
                  > 
                  <WhatsappLogo size={20} style={{marginRight:2}}/>
                  {dataStore?.url_whatsapp_api.split('/')[3]}
                </Link>
                </button>
                <button
                  className="border-1 border-black bg-black p-2 px-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
                >
                  {dataStore?.mail_direccion}
                </button>
                {
                  user ?
                  <button
                  className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
                  onClick={() => handleLogOut()}
                >
                  Salir
                </button> : null
                }
              </div>
            )}
          </div>
        </div>
      </div>
      <SubNavBar />
    </div>
  )
}
